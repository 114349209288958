<template>
	<div>
		<h2 class="content-block">
			<img src="@/assets/reg_form_header.png" class="header-image" />
			<div class="header-title">Alumni Registration Form</div>
		</h2>

		<DxPopup v-model:visible="videoPopup" :shading="true" shadingColor="#ccca" :showTitle="true" :showCloseButton="true" :dragEnabled="false" :hideOnOutsideClick="true" :wrapper-attr="{ class: 'welcome-popup' }"
			max-width="480px" max-height="520px" :fullScreen="smallScreenVideo"
			:onShown="videoPopupToggled" :onHiding="videoPopupToggled">
			<video controls src="../assets/AlumniVideo.mp4" ref="alumniVideo" width="100%" height="100%"/>
		</DxPopup>

		<DxPopup v-model:visible="submitError" @hidden="submitError = false" title="Error submitting survey"
			:shading="true" shadingColor="#ccca" :showTitle="true" :showCloseButton="true" :dragEnabled="false" :hideOnOutsideClick="true"
			max-width="400px" max-height="280px">
			<div class="submitsurvey-error-body">{{ submitErrorMsg }}</div>
		</DxPopup>

		<div class="content-block dx-card responsive-paddings application-form" v-if="existingAlumni.id">
			<div class="existing-warning">
				<i class="dx-icon dx-icon-check" style="font-size: 48px;color:green;"></i><br>
				<b>Thank you for submitting your alumni form.</b><br>
				If necessary, we will send you an email with any further details.
			</div>
		</div>

		<div class="content-block dx-card responsive-paddings application-form" v-if="!existingAlumni.id">
			<form @submit.prevent="submitAlumniForm" enctype="multipart/form-data">
				<DxForm id="alumni-dxform" ref="alumniForm" label-mode="floating" :col-count="1" :form-data="applicationData" :repaint-changes-only="true">
					<template #groupHeading="{ data }">
						<i :class="'dx-icon dx-icon-' + data.caption.split(';')[0]"/><span>{{ data.caption.split(';')[1] }}</span>
					</template>
					<template #uploadFileDrop="{ data }">
						<DropzoneFile layout="minimal" :label="data.editorOptions.label" :allowedExtensions="['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg']" @valueChanged="fileUploadChanged($event, data.dataField)" />
					</template>
					<DxTabbedItem >
						<DxTabPanelOptions :repaint-changes-only="true" :defer-rendering="false" :selected-index="tabSelectedIndex" @titleClick="formTabChanged" />
						
						<DxTab title="Personal Details">
							<DxSimpleItem>
								<DxBox :direction="smallScreenVideo ? 'col' : 'row'" width="100%" class="simple-item-descriptive dx-card dx-theme-accent-as-border-color">
									<DxItem :ratio="smallScreenVideo ? 0 : 1" :baseSize="smallScreenVideo ? 'auto' : 0">
										<p>Thank you for your interest! This survey will not take more than 10 minutes, but it can change your life or help your fellow nurses who are at the same step to enter the work life in PLNE phase.</p>
									</DxItem>
									<DxItem :ratio="smallScreenVideo ? 0 : 0" :baseSize="smallScreenVideo ? 300 : 200">
										<div class="simple-item-videobox alumni-videobox" @click="videoPopup = true">
											<div><i style="font-size:48px;" class="dx-icon-video"></i></div>
											<span>Play Video</span>
										</div>
									</DxItem>
								</DxBox>
							</DxSimpleItem>
							<DxGroupItem caption="user;Name and personal details" caption-template="groupHeading" :col-count="3">
								<DxSimpleItem data-field="last_name" :is-required="true" :label="{ text: 'Last/Family name' }" />
								<DxSimpleItem data-field="first_name" :is-required="true" />
								<DxSimpleItem data-field="middle_name" :is-required="true" />
								<DxSimpleItem data-field="gender" editor-type="dxSelectBox" :editor-options="genderListOptions" :is-required="true" />
								<DxSimpleItem data-field="birthday" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native', max: Date(), dateSerializationFormat: 'yyyy-MM-dd' }" :is-required="true" />
							</DxGroupItem>
							<DxGroupItem caption="tel;Contact details" caption-template="groupHeading" :col-count="3">
								<DxSimpleItem data-field="email" :is-required="true">
									<DxRequiredRule message="Email is required"/><DxEmailRule message="Email is invalid"/>
								</DxSimpleItem>
								<DxSimpleItem data-field="facebook" :is-required="false" :label="{ text: 'Facebook Messenger' }" />
								<DxEmptyItem />
								<DxSimpleItem data-field="address" :is-required="false" :col-span="3" :label="{ text: 'Current Address' }" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true, maxHeight: '180px' }" />
							</DxGroupItem>
							<DxGroupItem caption="runner;Graduation details" caption-template="groupHeading" :col-count="3">
								<DxSimpleItem data-field="graduation_school" :visible="!customSchoolName" :is-required="true" :label="{ text: 'School graduated at' }" editor-type="dxSelectBox" :editor-options="graduatedSchoolOptions" />
								<DxSimpleItem data-field="graduation_school" :visible="customSchoolName" :is-required="true" :label="{ text: 'School graduated at (Custom)' }" />
								<DxSimpleItem data-field="graduation_year" :is-required="true" :label="{ text: 'Year of graduation' }" editor-type="dxSelectBox" :editor-options="graduatedYearOptions" />
								<DxEmptyItem />
								<DxSimpleItem :col-span="3" css-class="group-hint-header">
									<i class="dx-icon dx-icon-info"></i>
									<span v-if="!customSchoolName">Click/tap on the boxes and type to search for your school, or <a href="#" @click.prevent="customSchoolName = true">click here</a> to type your own school.</span>
									<span v-else>Click/tap on the boxes and type to search for your school.</span>
								</DxSimpleItem>
							</DxGroupItem>
							<DxGroupItem :col-span="3">
								<DxButtonItem horizontal-alignment="right">
									<DxButtonOptions text="Next" type="default" :onClick="() => doFormTabChanged(1)" />
								</DxButtonItem>
							</DxGroupItem>
						</DxTab>

						<DxTab title="First Employment">
							<DxGroupItem caption="toolbox;First employment" caption-template="groupHeading" :col-count="3">
								<DxSimpleItem data-field="employ_first_look" :is-required="true" :label="{ text: 'Looking for first employment...' }" editor-type="dxSelectBox" :editor-options="firstEmploymentLookOptions" :col-span="3" />
								<DxSimpleItem data-field="employ_first_achieved" :is-required="true" :label="{ text: 'Got first employment...' }" editor-type="dxSelectBox" :editor-options="firstEmploymentAchievedOptions" :col-span="3" />
								<DxSimpleItem data-field="employ_support" :is-required="true" :label="{ text: 'Support for your first employment' }" editor-type="dxSelectBox" :editor-options="firstEmploymentSupportOptions" :col-span="3" />
								<DxSimpleItem data-field="employ_support_detail" :is-required="true" :col-span="3">
									<DxLabel v-if="applicationData.employ_support == 'yes'" text="What would have helped you (what did you miss)?" />
									<DxLabel v-else text="Who/what helped you find a job?" />
								</DxSimpleItem>
							</DxGroupItem>
							<DxGroupItem :col-span="3">
								<DxButtonItem horizontal-alignment="right">
									<DxButtonOptions text="Next" type="default" :onClick="() => doFormTabChanged(2)" />
								</DxButtonItem>
							</DxGroupItem>
						</DxTab>

						<DxTab title="Work Experience">
							<DxGroupItem caption="event;Total work experience as registered nurse since PNLE" caption-template="groupHeading" :col-span="3" :col-count="3">
								<DxSimpleItem data-field="total_work_years" :is-required="true" :label="{ text: 'Years experience' }" editor-type="dxNumberBox" :editor-options="{ min: 0, max: 100 }" />
								<DxSimpleItem data-field="total_work_months" :is-required="true" :label="{ text: 'Months experience' }" editor-type="dxNumberBox" :editor-options="{ min: 0, max: 12 }" />
							</DxGroupItem>
							<DxGroupItem caption="key;Your skills and competencies so far" caption-template="groupHeading" :col-span="3" :col-count="3">
								<DxSimpleItem data-field="skills_EHR" :is-required="true" :label="{ text: 'Experience with EHR' }" editor-type="dxSelectBox" :editor-options="skillsEHROptions" :col-span="3" />
								<DxSimpleItem data-field="skills_spec_training" :is-required="false" :label="{ text: 'Specialized Training' }" editor-type="dxTagBox" :editor-options="skillsSpecTrainingOptions" :col-span="3" />
								<DxSimpleItem :visible="needsSpecTrainOther" data-field="skills_spec_training_other" :is-required="false" :label="{ text: 'What other specialized training?' }" :col-span="3" />
								<DxSimpleItem data-field="skills_primary_field" :is-required="true" :label="{ text: 'I have the most work experience in...' }" editor-type="dxSelectBox" :editor-options="skillsFieldOptions" :col-span="2" />
								<DxSimpleItem data-field="skills_primary_time" :is-required="true" :label="{ text: 'Experience time' }" editor-type="dxSelectBox" :editor-options="skillsTimeOptions" :col-span="1" />
								<template v-for="(skill, idx) in secondarySkill" :key="'skillsSecondaryGroup' + (idx+1)">
									<DxSimpleItem :data-field="'skills_secondary_field[' + idx + ']'" :is-required="true" :label="{ text: 'Other relevant work experience in...' }" editor-type="dxSelectBox" :editor-options="skill.skill" :col-span="2" />
									<DxSimpleItem :data-field="'skills_secondary_time[' + idx + ']'" :is-required="true" :label="{ text: 'Experience time' }" editor-type="dxSelectBox" :editor-options="skill.time" :col-span="1" />
								</template>
								<DxButtonItem :col-span="3" horizontal-alignment="left"><DxButtonOptions text="Add more" :onClick="addSecondarySkill" /></DxButtonItem>
							</DxGroupItem>
							<DxGroupItem caption=";What is your current employment status?" caption-template="groupHeading" :col-span="3" :col-count="2">
								<DxButtonItem>
									<DxButtonOptions text="I am currently employed" icon="taskcomplete" width="100%" :onClick="currentJobStatusSet"
										:type="currentJobStatus == 'yes' ? 'default' : 'normal'" :height="currentJobStatus != '' ? '40px' : '100px'"
									/>
								</DxButtonItem>
								<DxButtonItem>
									<DxButtonOptions text="I am currently unemployed" icon="taskcomplete" width="100%" :onClick="currentJobStatusSet"
										:type="currentJobStatus == 'no' ? 'default' : 'normal'" :height="currentJobStatus != '' ? '40px' : '100px'"
									/>
								</DxButtonItem>
							</DxGroupItem>
						</DxTab>

						<DxTab title="Current Employment" :disabled="currentJobStatus != 'yes'">
							<DxGroupItem :visible="currentJobStatus == 'yes'" caption=";Employer Details" caption-template="groupHeading" :col-span="3" :col-count="3">
								<DxSimpleItem data-field="employer_site" editor-type="dxSelectBox" :editor-options="employerSiteOptions" :is-required="true" />
								<DxSimpleItem :visible="applicationData.employer_site == 'national'" :is-required="applicationData.employer_site == 'national'"
									data-field="employer_region" editor-type="dxSelectBox" :editor-options="employerSiteNational" :col-span="2"  />
								<DxSimpleItem :visible="applicationData.employer_site == 'international'" :is-required="applicationData.employer_site == 'international'"
									data-field="employer_country" editor-type="dxSelectBox" :editor-options="employerSiteInternational" :col-span="2" />
								<DxEmptyItem :visible="!applicationData.employer_site" :col-span="2" />
								<DxSimpleItem data-field="employer_kind" editor-type="dxSelectBox" :editor-options="employerKindOptions" :is-required="true" :col-span="1" />
								<DxSimpleItem :visible="!!needsEmployerKindSpecifics" :is-required="needsEmployerKindSpecifics ? true : false" data-field="employer_kind_specifics" :label="{ text: needsEmployerKindSpecifics }" :col-span="2" />
							</DxGroupItem>
							<DxGroupItem :visible="currentJobStatus == 'yes'" caption=";Job Specifics" caption-template="groupHeading" :col-span="3" :col-count="3">
								<DxSimpleItem data-field="job_position" :is-required="true" :label="{ text: 'Current working area/unit' }" editor-type="dxSelectBox" :editor-options="jobPositionOptions"  :col-span="3" />
								<DxSimpleItem data-field="job_duration_ends" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'rollers', dateSerializationFormat: 'yyyy-MM-01', displayFormat: 'monthAndYear' }" :is-required="true" :label="{ text: 'End date of employment contract' }" />
								<DxSimpleItem data-field="job_intent" editor-type="dxSelectBox" :editor-options="jobIntentOptions" :label="{ text: 'Your intention after' }" :is-required="true" :col-span="2" />
								<DxSimpleItem :visible="needsJobPrefSpecifics" :is-required="needsJobPrefSpecifics" 
									data-field="job_prefer_employer_kind" :label="{ text: 'What kind of employer would you prefer?' }" editor-type="dxSelectBox" :editor-options="jobPreferEmployerKindOptions" />
								<DxSimpleItem :visible="needsJobPrefSpecifics" :is-required="needsJobPrefSpecifics" 
									data-field="job_prefer_employer_site" :label="{ text: 'What is your preferred choice of employer site?' }" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteOptions" />
								<DxSimpleItem :visible="needsJobPrefSpecifics && applicationData.job_prefer_employer_site == 'national'" :is-required="needsJobPrefSpecifics && applicationData.job_prefer_employer_site == 'national'"
									data-field="job_prefer_employer_region" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteNational" :col-span="2" :label="{ text: 'Preferred Region' }" />
								<DxSimpleItem :visible="needsJobPrefSpecifics && applicationData.job_prefer_employer_site == 'international'" :is-required="needsJobPrefSpecifics && applicationData.job_prefer_employer_site == 'international'"
									data-field="job_prefer_employer_country" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteInternational" :col-span="2" :label="{ text: 'Preferred Country' }" />
								<DxEmptyItem :visible="!applicationData.job_prefer_employer_site" />
								<DxSimpleItem :visible="needsJobPrefSpecifics" :is-required="needsJobPrefSpecifics"
									data-field="job_prefer_employer_position" editor-type="dxSelectBox" :editor-options="jobPreferEmployerPositionOptions" :col-span="2" :label="{ text: 'Preferred future working area/unit' }" />
							</DxGroupItem>
							<DxGroupItem :col-span="3">
								<DxButtonItem horizontal-alignment="right">
									<DxButtonOptions text="Next" type="default" :onClick="() => doFormTabChanged(4)" />
								</DxButtonItem>
							</DxGroupItem>
						</DxTab>

						<DxTab title="Future Employment" :disabled="currentJobStatus == ''">
							<DxGroupItem :visible="currentJobStatus == 'no'" caption=";Job Application" caption-template="groupHeading" :col-span="3" :col-count="3">
								<DxSimpleItem data-field="employment_plans" editor-type="dxSelectBox" :editor-options="employmentPlansOptions" :is-required="true" :col-span="3" />
								<DxSimpleItem 
									data-field="job_prefer_employer_site" :label="{ text: 'What is your prefered choice of employer site?' }" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteOptions" />
								<DxSimpleItem :visible="applicationData.job_prefer_employer_site == 'national'" :is-required="applicationData.job_prefer_employer_site == 'national'"
									data-field="job_prefer_employer_region" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteNational" :col-span="2" :label="{ text: 'Preferred Region' }" />
								<DxSimpleItem :visible="applicationData.job_prefer_employer_site == 'international'" :is-required="applicationData.job_prefer_employer_site == 'international'"
									data-field="job_prefer_employer_country" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteInternational" :col-span="2" :label="{ text: 'Preferred Country' }" />
								<DxEmptyItem :visible="!applicationData.job_prefer_employer_site" :col-span="2" />
								<DxSimpleItem data-field="job_prefer_employer_position" editor-type="dxSelectBox" :editor-options="jobPreferEmployerPositionOptions" :col-span="3" :label="{ text: 'Preferred future working area/unit' }" />
							</DxGroupItem>
							<DxGroupItem :visible="!!currentJobStatus" caption=";Professional Development" caption-template="groupHeading" :col-span="3" :col-count="3">
								<DxSimpleItem data-field="development_goals" editor-type="dxSelectBox" :editor-options="developmentGoalOptions" :is-required="true" :col-span="2" />
								<DxSimpleItem :visible="applicationData.development_goals == 'other'" 
									data-field="development_goals_other" :label="{ text: 'What other goals?' }" :is-required="false" :col-span="1" />
								<DxEmptyItem :visible="!applicationData.development_goals || applicationData.development_goals != 'other'"  :col-span="1" />
								<DxSimpleItem data-field="development_interest_further" editor-type="dxSelectBox" :editor-options="developmentInterestFurtherOptions" :is-required="true" :label="{ text: 'Further Development' }" />
								<DxSimpleItem :visible="applicationData.development_interest_further == 'yes'"
									data-field="development_interest_further_detail" :label="{ text: 'What further education or training?' }" :col-span="2" :is-required="false" />
							</DxGroupItem>
							<DxGroupItem :visible="!!currentJobStatus" caption=";Adaptability, Commitment to Care" caption-template="groupHeading" :col-span="3" :col-count="1">
								<DxSimpleItem data-field="adapt_high_stress" editor-type="dxSelectBox" :label="{ text: 'How do you handle high-stress situations?' }" :editor-options="adaptHighStressOptions" :is-required="true" />
								<DxSimpleItem data-field="adapt_high_stress_tutorial" editor-type="dxSelectBox" :label="{ text: 'Would you like to have access to a tutorial/course on how to handle better high-stress situations?' }" :editor-options="adaptHighStressTutorialOptions" :is-required="true" />
								<DxSimpleItem data-field="commit_care_ensure" editor-type="dxTagBox" :label="{ text: 'How do you ensure high-quality patient care?' }" :editor-options="commitCareEnsureOptions" :is-required="true" />
							</DxGroupItem>
							<DxGroupItem :col-span="3">
								<DxButtonItem horizontal-alignment="right">
									<DxButtonOptions text="Next" type="default" :onClick="() => doFormTabChanged(5)" />
								</DxButtonItem>
							</DxGroupItem>
						</DxTab>

						<DxTab title="Finish" :disabled="currentJobStatus == ''">
							<DxGroupItem :col-span="3" :col-count="3" :visible="!!currentJobStatus">
								<DxSimpleItem :col-span="1" data-field="cvfile" template="uploadFileDrop" :label="{ text: 'Voluntary CV Upload' }">
								</DxSimpleItem>
								<DxSimpleItem :col-span="2">
									<span style="font-style:italic;">Your CV helps to provide more value to the survey and finally to empower you and the Philippine nurse community.</span>
								</DxSimpleItem>
							</DxGroupItem>
							<DxGroupItem :col-span="3" :col-count="3" :visible="!!currentJobStatus">
								<DxSimpleItem :col-span="3">
									<template #default>
										<DxBox direction="row">
											<DxItem :ratio="0" :baseSize="40">
												<DxCheckBox v-model:value="applicationData.agree_privacy_policy" />
											</DxItem>
											<DxItem :ratio="1">
												<div><div>I have read and agree with the <a href="/privacy.html" target="_blank">Privacy Policy</a> regarding usage of my data.</div></div>
											</DxItem>
										</DxBox>
									</template>
								</DxSimpleItem>
								<DxSimpleItem :col-span="2">
									<template #default>
										<DxBox direction="row">
											<DxItem :ratio="0" :baseSize="40">
												<DxCheckBox v-model:value="applicationData.allow_profile_matching" />
											</DxItem>
											<DxItem :ratio="1">
												Thank you for answering the questions. In the case that aiding tools or a platform for finding the dream employment site/employer of every nurse (matching platform) are developed from results out of this survey, I would like to have access to these tools or position my profile on the matching platform.
											</DxItem>
										</DxBox>
									</template>
								</DxSimpleItem>
								<DxButtonItem :visible="currentJobStatus != ''" :col-span="3" :button-options="{ type: 'success', text: 'Submit Alumni Form', useSubmitBehavior: false, onClick: submitAlumniForm }" />
							</DxGroupItem>
						</DxTab>

					</DxTabbedItem>
				</DxForm>
			</form>
			<DxLoadPanel :position="{ of: '.application-form' }" :shading="true" shadingColor="rgba(0,0,0,0.4)" v-model:visible="loadingVisible" />
		</div>
	</div>
</template>

<script setup>
import { DxForm, DxSimpleItem , DxGroupItem, DxButtonItem, DxButtonOptions, DxEmptyItem, DxRequiredRule, DxEmailRule, DxTabbedItem, DxTab, DxTabPanelOptions, DxLabel } from "devextreme-vue/form";
import DxTextArea from 'devextreme-vue/text-area';				// eslint-disable-line no-unused-vars
import DxSelectBox from 'devextreme-vue/select-box';			// eslint-disable-line no-unused-vars
import { DxDateBox } from 'devextreme-vue/date-box';			// eslint-disable-line no-unused-vars
import DxCheckBox from 'devextreme-vue/check-box';				// eslint-disable-line no-unused-vars
import { DxTagBox } from 'devextreme-vue/tag-box';				// eslint-disable-line no-unused-vars
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxPopup from "devextreme-vue/popup"
import { inject, ref, computed, nextTick } from "vue";
import { useRouter } from 'vue-router';
import to from 'await-to-js';
import notify from 'devextreme/ui/notify';
import countries from '../countries.js';
import regions from "../regions.js";
import specialities from "../specialities.js";
import DropzoneFile from '../components/dropzone-file';
import schools from "../schools.js";
import DataSource from "devextreme/data/data_source";
import { sizes } from '../utils/media-query';
import appInfo from "@/app-info.js";

const $http = inject('$http');
const router = useRouter();
const colCountByScreen = {										// eslint-disable-line no-unused-vars
	xs: 1,
	sm: 2,
	md: 3,
	lg: 4
}

const alumniForm = ref(null);
const videoPopup = ref(false);
const alumniVideo = ref(null);
const smallScreenVideo = sizes()['screen-x-small'] || sizes()['screen-small'];

const currentJobStatus = ref('');			// Values: '', 'yes' or 'no'
const currentJobStatusSet = function(ev) {
	let newValue = ev.element.innerText.toLowerCase().includes('unemployed') ? 'no' : 'yes';				// TODO: Oh god, figure out a better way!
	currentJobStatus.value = newValue == currentJobStatus.value ? '' : newValue;		// Click to cancel status
	nextTick(() => {
		doFormTabChanged(currentJobStatus.value == '' ? 2 : (currentJobStatus.value == 'yes' ? 3 : 4));
	});
	return false;
}
const customSchoolName = ref(false);

const existingAlumni = ref({ id: false, edit_secret: '', updated_at: '', updated_at_ui: '', editing: false });
const applicationData = ref({
	allow_profile_matching: true,
	agree_privacy_policy: false,
	skills_secondary_field: [],
	skills_secondary_time: [],
	employ_support: null,
});
let loadingVisible = ref(false);
let submitError = ref(false);
let submitErrorMsg = ref('');
const tabSelectedIndex = ref(0);

const existingAlumniStore = window.localStorage.getItem(appInfo.localPrefix + 'AlumniForm')
if (existingAlumniStore) {
	// Load existing alumni ID and secret from local storage if possible.
	existingAlumni.value.id = existingAlumniStore.split(' ')[0];
	existingAlumni.value.edit_secret = existingAlumniStore.split(' ')[1];
}

const genderListOptions = { dataSource: [ 'Male', 'Female', 'Diverse' ] };
const schoolsData = new DataSource({ store: schools, sort: 'title', pageSize: 10, paginate: true });
const graduatedSchoolOptions = { dataSource: schoolsData, valueExpr: 'slug', displayExpr: 'title', searchEnabled: true };
const graduatedYearOptions = { dataSource: [], searchEnabled: true };
const upToYear = (new Date()).getFullYear();
for (var i = upToYear; i >= 1900; i--) {
	graduatedYearOptions.dataSource.push(i);
}
const firstEmploymentLookOptions = { grouped: true, displayExpr: 'text', valueExpr: 'id', dataSource: [
	{ key: "When did you start looking for your first job as a nurse?", items: [
		{ id: 'before', text: "Before passing PNLE" },
		{ id: 'after', text: "After passing PNLE" },
		{ id: 'never', text: "Never" },
	]},
]};
const firstEmploymentAchievedOptions = { grouped: true, displayExpr: 'text', valueExpr: 'id', dataSource: [
	{ key: "When did you get your first job as a nurse?", items: [
		{ id: 'before', text: "Before passing PNLE" },
		{ id: 'after', text: "After passing PNLE" },
		{ id: 'never', text: "Never" },
	]},
]};
const firstEmploymentSupportOptions = { displayExpr: 'text', valueExpr: 'id', dataSource: [ 
	{ id: 'yes', text: "I missed the support in finding an employer for my first job" },
	{ id: 'no', text: "I did not miss the support in finding an employer for my first job" },
]};

const skillFieldsCustomItemCreate = (args) => {
	// Create a new item under the 'other' group.
	if (!args.text) {
		args.customItem = null;
		return;
	}
	const { component, text } = args;
	const currentItems = component.option('items');
	const otherGroup = currentItems.find(cu => cu.key == 'Other');
	if (!otherGroup || !otherGroup.items[0]) {
		args.customItem = null;
		return;
	}
	otherGroup.items[0].name = text.trim();
	args.customItem = otherGroup.items[0];
};
const skillsFieldOptions = {
	acceptCustomValue: true,
	displayExpr: 'name', valueExpr: 'name', grouped: true, 
	placeholder: "Select or type a custom item...",
	onCustomItemCreating: skillFieldsCustomItemCreate,
	dataSource: JSON.parse(JSON.stringify(specialities)),
};
const skillsTimeOptions = {
	displayExpr: 'text', valueExpr: 'id',
	dataSource: [
		{ id: '0-1', text: "0-1 years" },
		{ id: '1-2', text: "1-2 years" },
		{ id: '2-4', text: "2-4 years" },
		{ id: '>4', text: ">4 years" },
	],
};
const secondarySkill = ref([
]);

const addSecondarySkill = () => {
	var newSkillDef = JSON.parse(JSON.stringify(skillsFieldOptions));
	newSkillDef.onCustomItemCreating = skillFieldsCustomItemCreate;
	newSkillDef.buttons = [
		{ location: 'after', name: 'removeSkill', options: { icon: 'trash', onClick: removeSecondarySkill } }
	];
	secondarySkill.value.push({
		skill: newSkillDef,
		time: JSON.parse(JSON.stringify(skillsTimeOptions)),
	});
}
const removeSecondarySkill = (ev) => {
	// [***] UGLY HACK: Find index of the row we just clicked on, that matches our index we need to remove! This can be seen in the hidden input attached to this editor.
	let container = ev.element.closest('.dx-selectbox-container');
	let idx = parseInt(container.querySelector('input[type=hidden]').name.split('[')[1].split(']')[0]);
	applicationData.value.skills_secondary_field.splice(idx, 1);
	applicationData.value.skills_secondary_time.splice(idx, 1);
	secondarySkill.value.splice(idx, 1);
}

const skillsEHROptions = {
	displayExpr: 'text', valueExpr: 'id', grouped: true,
	dataSource: [
		{ key: 'Rate your proficiency with electronic health record (EHR) systems:', items: [
			{ id: 'none', text: "None" },
			{ id: 'beginner', text: "Beginner" },
			{ id: 'intermediate', text: "Intermediate" },
			{ id: 'advanced', text: "Advanced" },
			{ id: 'expert', text: "Expert" },
		] },
	],
};

const skillsSpecTrainingOptions = {
	displayExpr: 'text', valueExpr: 'id', grouped: true,
	dataSource: [
		{ key: 'Have you completed any specialized training relevant to the following areas?', items: [
			{ id: 'infection', text: "Infection Control" },
			{ id: 'wound', text: "Wound Care" },
			{ id: 'pain', text: "Pain Management" },
			{ id: 'critical', text: "Critical Care" },
			{ id: 'midwife', text: "Midwifery" },
			{ id: 'mental', text: "Mental Health" },
			{ id: 'other', text: "Other" },
		] },
	],
};
const needsSpecTrainOther = computed(() => {
	if ('skills_spec_training' in applicationData.value) {
		return applicationData.value['skills_spec_training'].includes('other');
	}
	return false;
});

const employerSiteOptions = { dataSource: [ { val: 'national', label: 'National Employer' }, { val: 'international', label: 'International Employer' } ], valueExpr: 'val', displayExpr: 'label' };
const employerSiteNational = { dataSource: regions, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const employerSiteInternational = { dataSource: countries, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const employerKindOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "Hospital", items: [ { id: 'hospital-primary', name: 'Primary Hospital' }, { id: 'hospital-secondary', name: 'Secondary Hospital' }, { id: 'hospital-tertiary', name: 'Tertiary Hospital' } ] },
		{ key: "", items: [
			{ id: "community", name: "Community" },
			{ id: "company-school", name: "Company/School" },
			{ id: "nursing-home", name: "Nursing home" },
			{ id: "elderly-home", name: "Elderly home" },
			{ id: "non-sector", name: "Non health-care sector" },
			{ id: "other", name: "Other" },
		] },
	],
};
const needsEmployerKindSpecifics = computed(() => {
	let otherLabel = 'employer_kind' in applicationData.value ? String(applicationData.value['employer_kind']) : null;
	if (!otherLabel)
		return false;
	if (otherLabel == 'non-sector')
		return "Other sector of employer";
	if (otherLabel == 'other')
		return "Other type of employer";
	return false;
});
const jobPositionOptions = {
	acceptCustomValue: true,
	displayExpr: 'name', valueExpr: 'name', grouped: true, 
	placeholder: "Select or type a custom item...",
	onCustomItemCreating: skillFieldsCustomItemCreate,
	dataSource: JSON.parse(JSON.stringify(specialities)),
};

const jobIntentOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "Do you intent to stay with this employer for a long time or do you aim for another destination?", items: [
			{ id: "stay", name: "I intend to stay with this employer, and try to prolong my contract" },
			{ id: "change", name: "I want to change employer after the end of the employment contract" },
		] },
	],
}
const needsJobPrefSpecifics = computed(() => {
	return 'job_intent' in applicationData.value && String(applicationData.value['job_intent']) == 'change';
});

const jobPreferEmployerKindOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "Hospital", items: [ { id: 'hospital-primary', name: 'Primary Hospital' }, { id: 'hospital-secondary', name: 'Secondary Hospital' }, { id: 'hospital-tertiary', name: 'Tertiary Hospital' }, { id: 'hospital-any', name: 'Any Hospital (does not matter)' } ] },
		{ key: "", items: [
			{ id: "community", name: "Community" },
			{ id: "company-school", name: "Company/School" },
			{ id: "nursing-home", name: "Nursing home" },
			{ id: "elderly-home", name: "Elderly home" },
			{ id: "non-sector", name: "Non health-care sector" },
			{ id: "other", name: "Other" },
		] },
	],
}

const jobPreferEmployerSiteOptions = { dataSource: [ { val: 'national', label: 'National Employer' }, { val: 'international', label: 'International Employer' } ], valueExpr: 'val', displayExpr: 'label' };
const jobPreferEmployerSiteNational = { dataSource: regions, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const jobPreferEmployerSiteInternational = { dataSource: countries, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const jobPreferEmployerPositionOptions = {
	acceptCustomValue: true,
	displayExpr: 'name', valueExpr: 'name', grouped: true, 
	placeholder: "Select or type a custom item...",
	onCustomItemCreating: skillFieldsCustomItemCreate,
	dataSource: JSON.parse(JSON.stringify(specialities)),
};

const employmentPlansOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "I have applied for a job", items: [
			{ id: "applied-already-national", name: "Yes, I applied for a job nationally but without success/waiting for feedback" },
			{ id: "applied-already-international", name: "Yes, I applied for a job internationally but without success/waiting for feedback" },
			{ id: "not-applied", name: "I won't apply for a job" },
		] },
	],
};

const developmentGoalOptions = {
	grouped: true, displayExpr: 'name', valueExpr: 'id',
	dataSource: [
		{ key: "What are your professional nursing goals for the next 5 years?", items: [
			{ id: "gain-speciality", name: "Gain a specialty certification" },
			{ id: "move-leadership", name: "Move into a leadership position" },
			{ id: "engage-research", name: "Engage in research" },
			{ id: "work-international", name: "Work internationally" },
			{ id: "other", name: "Other" },
		] },
	],
};
const developmentInterestFurtherOptions = {
	grouped: true, displayExpr: 'name', valueExpr: 'id',
	dataSource: [
		{ key: "Are you interested in opportunities for further education or training?", items: [
			{ id: "yes", name: "Yes, I am interested in further opportunities" },
			{ id: "no", name: "Not - not interested" },
		] },
	],
};

const adaptHighStressOptions = {
	displayExpr: 'name', valueExpr: 'id',
	dataSource: [
		{ id: 'perform-well', name: "I perform well under pressure" },
		{ id: 'manage', name: "I manage with some difficulty" },
		{ id: 'challenge', name: "I find it challenging to perform" },
		{ id: 'none', name: "Not applicable" },
	]
};
const adaptHighStressTutorialOptions = {
	displayExpr: 'name', valueExpr: 'id',
	dataSource: [
		{ id: 'yes', name: "Yes" },
		{ id: 'no', name: "No" },
	]
};
const commitCareEnsureOptions = {
	displayExpr: 'name', valueExpr: 'id', grouped: true,
	dataSource: [
		{ key: "Select all that apply", items: [
			{ id: 'follow-protocols', name: "Following protocols strictly" },
			{ id: 'regular-training', name: "Regular training updates" },
			{ id: 'feedback', name: "Patient feedback" },
			{ id: 'collab', name: "Team collaboration" },
			{ id: 'other', name: "Other" },
		] },
	]
};

const fileUploadChanged = (e, dataField) => {
	// console.log("fileUploadChanged: ", dataField, e);
	applicationData.value[dataField] = e.id;
}

const submitAlumniForm = async () => {
	// Manually switch to the tab with the first invalid field, since we don't highlight them in any other way.
	let validationResult = alumniForm.value.instance.validate();
	if (!validationResult.isValid) {
		let switchToTab = null;
		validationResult.brokenRules.forEach(rule => {
			let ruleElement = rule.validator.element();
			let tabElement = ruleElement?.closest('.dx-item[role=tabpanel]') ?? null;
			let tabIndex = tabElement ? [...tabElement.parentElement.children].indexOf(tabElement) : null;
			if (tabIndex !== null && switchToTab == null) {
				switchToTab = tabIndex;
			}
		});
		if (switchToTab != null	) {
			// We must switch to the tab first, then validate, otherwise the validation elements are not visible!	
			doFormTabChanged(switchToTab);
			nextTick(() => {
				alumniForm.value.instance.validate();
			});
		}
		return;
	}

	if (!applicationData.value.agree_privacy_policy) {
		alert("Sorry, you must agree to the privacy policy before submitting your alumni form!");
		return;
	}	
	applicationData.value.current_job_status = Boolean(currentJobStatus.value == 'yes');
	
	loadingVisible.value = true;
	let returnVal, err;
	let postUrl = '/alumniRegister';
	applicationData.value['_method'] = 'POST';
	[ err, returnVal ] = await to($http.post(postUrl, applicationData.value));
	loadingVisible.value = false;
	if (err) {
		console.error([ err ]);
		let errorMsg = err.message ? err.message : 'Unknown error';
		if (err.response && err.response.statusText) {
			errorMsg += "\n" + err.response.statusText;
		}
		submitError.value = true;
		submitErrorMsg.value = "Error submitting your form:\n" + errorMsg;
		return;
	}
	
	// When complete, we store the existing alunni ID and secret in local storage as an easy way to mark that the form has been completed (since this is unauthenticated).
	// Secrets are split by space since these are never generated.
	window.localStorage.setItem(appInfo.localPrefix + 'AlumniForm', returnVal.data.id + ' ' + returnVal.data.edit_secret);
	notify({ message: "Your alumni form has been submitted successfully! Thank you for your time.", position: 'center', shading: true, shadingColor: '#cccc' }, "success", 3000);
	setTimeout(() => {
		router.go(0);
	}, 3000);
}

// The tab will re-render on form re-render and not save the current state, so we need to handle this manually.
// We also call this manually on next/prev buttons and certain other buttons.
const formTabChanged = (ev) => {
	doFormTabChanged(ev.itemIndex);
};
const doFormTabChanged = (tabIndex) => {
	tabSelectedIndex.value = tabIndex;
}

const videoPopupToggled = () => {
	if (videoPopup.value) {		// Video was just opened
		alumniVideo.value.play();
	} else {					// Video was just closed
		alumniVideo.value.pause();
	}
};
</script>

<style>
.header-image {
	width: 100%;
	max-width: 1200px;
}
.header-title {
	text-align: center;
	text-transform: uppercase;
	width: 100%; max-width: 1200px;
	border-bottom: 4px double black;
}
.header-subtitle {
	text-align: center;
	width: 100%; max-width: 1200px;
	font-size: 20px;
}
.application-form {
	max-width: 1200px;
}
.application-form .existing-warning {
	text-align: center;
}
.simple-item-descriptive {
	padding: 0 20px;
	border-left: 6px solid;
	text-align: justify;
}
.simple-item-descriptive p {
	padding-right: 15px;
}
.simple-item-descriptive p+p {
	margin-top: 0;
}
.simple-item-videobox.alumni-videobox {
	display: flex;
	justify-content: center;
	color: #efefef;
	margin: 10px;
	font-size: 24px;
	text-align: center;
	background: #ccc;
	background-image: url(../assets/AlumniVideo.th.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border: 2px solid #999;
	text-shadow: 2px 2px 3px #040404;
	cursor: pointer;
	min-height: 150px;
}
.dx-dateviewroller-day {
    display: none;
}
.welcome-popup .dx-popup-content {
	overflow: hidden;
}
.submitsurvey-error-body {
	white-space: pre-line;
	border-left: 2px solid red;
	padding-left: 15px;
}
.group-hint-header {
	font-style: italic;
	padding: 0 !important;
}
.group-hint-header span {
	vertical-align: middle;
}
.group-hint-header i {
	vertical-align: middle;
	padding-right: 5px;
}
</style>

